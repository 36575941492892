<template>
    <div class="pt-10">
        <v-row v-if="stats">
            <v-col class="text-center">
                <h3>Users</h3>
                <h1>{{ stats.userCount }}</h1>
            </v-col>
            <v-col class="text-center">
                <h3>Vessels</h3>
                <h1>{{ stats.vesselCount }}</h1>
            </v-col>
            <v-col class="text-center">
                <h3>Total Gallons</h3>
                <h1>{{ stats.gallons.toLocaleString('en-US') }}</h1>
            </v-col>
        </v-row>
        <v-row style="margin-top: 100px">
            <v-col cols="12" sm="4" xl="3" offset-xl="2">
                <v-card height="100%" min-height="300" rounded>
                    <v-list lines="two" density="compact">
                        <v-list-subheader>Recent Users</v-list-subheader>
                        <v-list-item v-if="loadingRecentUsers" class="d-flex justify-center">
                            <v-progress-circular indeterminate color="primary"></v-progress-circular>
                        </v-list-item>
                        <div v-else-if="!loadingRecentUsers && recentUsers">
                            <v-list-item
                                v-for="user of recentUsers"
                                :key="user.userId"
                                :title="userTitle(user)"
                                :subtitle="userSubTitle(user)"
                                :to="{ name: 'AccountDetails', params: { accountId: user.accountId } }"
                                append-icon="mdi-chevron-right"
                            />
                        </div>
                        <div v-else class="d-flex justify-center">
                            <h3>No Recent Users</h3>
                        </div>
                    </v-list>
                </v-card>
            </v-col>
            <v-col cols="12" sm="4" xl="2" class="text-center d-none d-sm-inline-block">
                <div class="searchTip" @click="simulateSearchKeypress">
                    <small>SEARCH</small>
                    <v-icon icon="mdi-chevron-up" class="caret" /> + <span class="s">S</span>
                </div>
            </v-col>
            <v-col cols="12" sm="4" xl="3">
                <v-card height="100%" min-height="300" rounded>
                    <v-list lines="two" density="compact" v-if="recentVessels">
                        <v-list-subheader>Recent Vessels</v-list-subheader>
                        <v-list-item v-if="loadingRecentVessels" class="d-flex justify-center">
                            <v-progress-circular indeterminate color="primary"></v-progress-circular>
                        </v-list-item>
                        <div v-else-if="!loadingRecentVessels && recentVessels">
                            <v-list-item
                                v-for="vessel of recentVessels"
                                :key="vessel.vesselId"
                                :title="vesselTitle(vessel)"
                                :subtitle="vesselSubTitle(vessel)"
                                :to="{ name: 'VesselDetails', params: { accountId: vessel.accountId, vesselId: vessel.vesselId } }"
                                append-icon="mdi-chevron-right"
                            />
                        </div>
                        <div v-else class="d-flex justify-center">
                            <h3>No Recent Users</h3>
                        </div>
                    </v-list>
                </v-card>
            </v-col>
        </v-row>
    </div>
</template>

<script lang="ts" setup>
import { useApi } from '@/api'
import { useBreadCrumbsStore } from '@/stores/breadCrumbs'
import type { RecentAccountUserV1, RecentVesselV1 } from '@general-galactic/crystal-api-client'
import { capitalize } from '@general-galactic/crystal-webapp-utils'
import { onMounted, ref } from 'vue'

const breadCrumbsStore = useBreadCrumbsStore()

const stats = ref()
const loadingRecentUsers = ref(false)
const recentUsers = ref<RecentAccountUserV1[] | undefined>()

const loadingRecentVessels = ref(false)
const recentVessels = ref<RecentVesselV1[] | undefined>()

const getStats = async () => {
    stats.value = await useApi().getGeneralStatsV1()
}

const simulateSearchKeypress = () => {
    const event = new KeyboardEvent('keydown', { key: 's', ctrlKey: true })
    window.dispatchEvent(event)
}

const userTitle = (user: RecentAccountUserV1): string => {
    let title = user.firstName
    if (user.lastName) title += ` ${user.lastName}`
    return title ?? 'unknown'
}

const userSubTitle = (user: RecentAccountUserV1): string => {
    let subTitle = user.email
    if (user.accountId) subTitle += `, account #${user.accountId}`
    return subTitle
}

const vesselTitle = (vessel: RecentVesselV1): string => {
    const title = `${vessel.name}, ${capitalize(vessel.type)}`
    return title ?? 'unknown'
}

const vesselSubTitle = (vessel: RecentVesselV1): string => {
    let subTitle = ''
    if (vessel.location) {
        subTitle += `${vessel.location.line1}, ${vessel.location.locality}, ${vessel.location.administrativeAreaLevel1} ${vessel.location.postalCode}`
    }
    if (vessel.accountId) {
        if (subTitle.length > 0) subTitle += ', '
        subTitle += `account #${vessel.accountId}`
    }
    return subTitle
}

const getRecentUsers = async () => {
    try {
        loadingRecentUsers.value = true
        const result = await useApi().getRecentAccountUsersV1({ limit: 10 })
        recentUsers.value = result.users
    } finally {
        loadingRecentUsers.value = false
    }
}

const getRecentVessels = async () => {
    try {
        loadingRecentVessels.value = true
        const result = await useApi().getRecentVesselsV1({ limit: 10 })
        recentVessels.value = result.vessels
    } finally {
        loadingRecentVessels.value = false
    }
}

onMounted(() => {
    getStats()
    getRecentUsers()
    getRecentVessels()
    breadCrumbsStore.$patch({
        items: [
            {
                text: `Home`
            }
        ]
    })
})
</script>

<style lang="scss" scoped>
h3 {
    font-weight: 300;
}

h1 {
    font-size: 3rem;
}

.searchTip {
    border: 1px solid silver;
    border-radius: 10px;
    display: inline-block;
    padding: 10px;
    font-size: 30px;
    font-weight: 300;
    color: #555;
    margin-top: 100px;

    small {
        font-size: 14px;
        display: block;
    }

    .caret {
        position: relative;
        top: -3px;
    }

    .s {
        display: inline-block;
        font-weight: 500;
        color: black;
        width: 45px;
    }
}
</style>
