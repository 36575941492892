<template>
    <v-container fluid>
        <v-row v-if="vessel" class="mt-5 mb-8">
            <v-col class="d-flex justify-start">
                <div class="vesselDisc mr-10 d-flex flex-column align-center">
                    <vessel-disc :vessel="vessel" />
                    <v-btn v-if="showEngineeringOptions" color="red" icon size="small" class="editStatusButton" @click="showUpdateStatusDialog = true">
                        <v-icon icon="mdi-database-edit" color="white" />
                    </v-btn>
                    <v-chip v-if="vessel.states.waitForNextSample" class="mt-3 mr-2" color="orange" variant="outlined">Waiting for Sample</v-chip>
                    <v-tooltip :text="vessel.status?.infoSentence">
                        <template v-if="vessel.states.acclimating" v-slot:activator="{ props }">
                            <v-chip v-bind="props" class="mt-3" color="orange" variant="outlined"
                                >Acclimating
                                <v-btn
                                    size="small"
                                    color="red"
                                    density="compact"
                                    variant="text"
                                    icon="mdi-timer-off"
                                    title="Stop Acclimating"
                                    @click="stopAcclimating"
                                    class="ml-2"
                                ></v-btn
                            ></v-chip>
                        </template>
                    </v-tooltip>

                    <v-table density="compact" class="mt-5 chemEngineInfo">
                        <tbody>
                            <tr>
                                <td>
                                    <span v-if="vessel?.status?.dates?.lastStatusUpdatedDate !== undefined">
                                        <time-ago prepend="Last updated" :date="vessel.status.dates.lastStatusUpdatedDate" :timezone-id="vesselTimezone" />
                                    </span>
                                    <span v-else>Never Updated</span>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <span v-if="vessel?.status?.dates?.nextStatusUpdateDate !== undefined">
                                        <time-ago prepend="Next update" :date="vessel.status.dates.nextStatusUpdateDate" :timezone-id="vesselTimezone" />
                                    </span>
                                    <span v-else>No Update Scheduled</span>
                                    <v-btn
                                        @click="runTimedChemCheck"
                                        variant="text"
                                        color="primary"
                                        size="small"
                                        icon="mdi-update"
                                        title="Run a Timed Chem Check Now"
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td class="p-2">
                                    <v-btn :to="{ name: 'VesselChemCheckHistory', params: { accountId, vesselId } }" variant="text" color="primary" size="small"
                                        >View Chem Check History</v-btn
                                    >
                                </td>
                            </tr>
                        </tbody>
                    </v-table>
                </div>

                <div class="d-flex align-center justify-start flex-wrap vesselCards">
                    <vessel-card @edit-vessel="showEditVessel = true" />
                    <location-card :account-id="accountId" :vessel-id="vesselId" @click="navigateToGoogleMaps" @edit-location="showEditVessel = true" />
                    <monitor-card
                        :account-id="accountId"
                        :vessel-id="vesselId"
                        @click="navigateToMonitorDetails"
                        @attached-monitor="vesselUpdated"
                        @detached-monitor="vesselUpdated"
                    />
                    <account-users-card :account-id="accountId" />
                    <subscription-card
                        :account-id="accountId"
                        :vessel-id="vesselId"
                        :monitor-id="monitorId"
                        :subscriptions="vessel.subscriptions"
                        :subscription-upgrades="vessel.subscriptionUpgrades"
                        :add-payment-method-banner="vessel.addPaymentMethodBanner"
                        @added-subscription="vesselUpdated"
                    />
                    <account-health-card :account-id="accountId" />
                </div>
            </v-col>
            <v-menu>
                <template v-slot:activator="{ props }">
                    <v-btn icon="mdi-dots-vertical" v-bind="props"></v-btn>
                </template>
                <v-list>
                    <v-list-item v-if="!isAcclimating" @click="startAcclimating" button title="Start Acclimating">
                        <template v-slot:prepend>
                            <v-icon icon="mdi-clock-start" class="mr-2"></v-icon>
                        </template>
                    </v-list-item>
                    <v-list-item v-else-if="isAcclimating" @click="stopAcclimating" button title="Stop Acclimating">
                        <template v-slot:prepend>
                            <v-icon icon="mdi-timer-off" class="mr-2"></v-icon>
                        </template>
                    </v-list-item>
                    <v-list-item @click="showAddWaterReportModal = true" button title="Add Water Report">
                        <template v-slot:prepend>
                            <v-icon icon="mdi-plus" class="mr-2"></v-icon>
                        </template>
                    </v-list-item>
                    <v-divider></v-divider>
                    <v-list-item button @click="showStatusJSON" title="View Status JSON">
                        <template v-slot:prepend>
                            <v-icon icon="mdi-eye" class="mr-2"></v-icon>
                        </template>
                    </v-list-item>
                    <v-list-item button @click="showVesselTestingControlsModal = true" title="Edit for Testing">
                        <template v-slot:prepend>
                            <v-icon icon="mdi-bug" class="mr-2"></v-icon>
                        </template>
                    </v-list-item>
                    <v-list-item
                        button
                        :href="`https://us-east-1.console.aws.amazon.com/dynamodbv2/home?region=us-east-1#edit-item?table=vessel_status&itemMode=2&pk=${vesselId}&sk&route=ROUTE_ITEM_EXPLORER`"
                        target="_aws"
                        title="View Status on DynamoDB"
                    >
                        <template v-slot:prepend>
                            <v-icon icon="mdi-aws" class="mr-2" color="orange"></v-icon>
                        </template>
                    </v-list-item>
                    <v-list-item button @click="viewProtocolInfo" title="View Protocol Info">
                        <template v-slot:prepend>
                            <v-icon icon="mdi-eye" class="mr-2"></v-icon>
                        </template>
                    </v-list-item>
                    <v-divider></v-divider>
                    <v-list-item button @click="showConfirmDeleteVessel" title="Delete Vessel">
                        <template v-slot:prepend>
                            <v-icon icon="mdi-delete" class="mr-2" color="red"></v-icon>
                        </template>
                    </v-list-item>
                    <v-divider></v-divider>
                    <v-list-item v-if="vessel?.subscriptions?.length" button @click="showConfirmDeleteSubscription" title="Delete Subscription">
                        <template v-slot:prepend>
                            <v-icon icon="mdi-delete-alert" class="mr-2" color="red"></v-icon>
                        </template>
                    </v-list-item>
                    <v-list-item v-else button @click="showConfirmMigrateSubscription" title="Migrate to Subscription">
                        <template v-slot:prepend>
                            <v-icon icon="mdi-cash" class="mr-2" color="red"></v-icon>
                        </template>
                    </v-list-item>
                </v-list>
            </v-menu>
        </v-row>

        <v-row v-if="vessel" class="mb-8">
            <v-col sm="12" md="6">
                <v-card class="fill-height">
                    <v-card-title class="d-flex justify-space-between"> READINGS </v-card-title>
                    <v-card-text>
                        <vessel-status-readings
                            :monitor-readings="vessel.status?.monitorReadings"
                            :report-readings="vessel.status?.reportReadings"
                            :computed-readings="vessel.status?.computedReadings"
                            :vessel-id="vesselId"
                            :account-id="accountId"
                            :vessel-timezone-id="vesselTimezone"
                            :low-temp-mode="vessel.status?.lowTempMode"
                            @updated-reading="updatedReading"
                        />
                        <v-row>
                            <v-col class="text-left">
                                <v-tooltip
                                    v-if="vessel.status?.dates?.eventHorizonDate"
                                    text="All samples before this date are ignored for readings & actions. This date is advanced when the user performs refills or detaches and attaches sensors."
                                >
                                    <template v-slot:activator="{ props }">
                                        Event Horizon: {{ format(vessel.status?.dates?.eventHorizonDate, 'Pp') }}
                                        <v-icon v-bind="props" size="small" color="grey-lighten-1" icon="mdi-information"></v-icon>
                                    </template>
                                </v-tooltip>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>

            <v-col sm="12" md="6">
                <v-card class="fill-height">
                    <v-card-title class="d-flex justify-space-between">
                        ACTIONS

                        <span>
                            <v-btn @click="showActionStatusesModal = true" color="blue-darken-2" density="compact" class="mr-3">View Action Statuses</v-btn>
                            <v-btn :to="{ name: 'VesselActionHistory', params: { accountId, vesselId } }" color="blue-darken-2" density="compact"
                                >View History</v-btn
                            >
                        </span>
                    </v-card-title>
                    <v-card-text>
                        <v-list v-if="actions && actions.length > 0">
                            <v-list-item
                                v-for="action in actions"
                                :key="action.type"
                                :value="action.type"
                                elevation="2"
                                rounded
                                max-width="600px"
                                @click="showAction(action)"
                                class="actionButton"
                            >
                                <template v-slot:prepend>
                                    <img v-if="action.iconUrl" :src="action.iconUrl" class="actionIcon mr-3" />
                                </template>
                                <v-list-item-title>{{ action.title }}</v-list-item-title>
                                <v-list-item-subtitle>
                                    <span v-if="lastCompletedDateForActionType(action.type)"
                                        ><time-ago prepend="last completed" :date="lastCompletedDateForActionType(action.type)" :timezone-id="vesselTimezone"
                                    /></span>
                                </v-list-item-subtitle>
                            </v-list-item>
                        </v-list>
                        <h3 v-else>No Actions</h3>

                        <v-list v-if="suppressedActions && suppressedActions.length > 0">
                            <v-list-item
                                v-for="action in suppressedActions"
                                :key="action.actionType"
                                elevation="2"
                                rounded
                                max-width="600px"
                                class="suppressedAction"
                            >
                                <template v-slot:prepend>
                                    <img v-if="action.iconUrl" :src="action.iconUrl" class="actionIcon mr-3" />
                                </template>
                                <v-list-item-title>
                                    {{ action.actionType }}
                                </v-list-item-title>
                                <v-list-item-subtitle>
                                    <v-tooltip :text="suppressedActionEndDate(action)">
                                        <template v-slot:activator="{ props }">
                                            <span v-bind="props">{{ suppressedActionSubTitle(action) }}</span>
                                        </template>
                                    </v-tooltip>
                                    <span v-if="lastCompletedDateForActionType(action.actionType)"
                                        >,
                                        <time-ago
                                            prepend="last completed"
                                            :date="lastCompletedDateForActionType(action.actionType)"
                                            :timezone-id="vesselTimezone"
                                    /></span>
                                </v-list-item-subtitle>
                                <template v-slot:append>
                                    <v-btn
                                        v-if="action.reason === 'snoozed'"
                                        color="grey-lighten-1"
                                        icon="mdi-cancel"
                                        variant="text"
                                        class="cancelSnooze"
                                        @click="clickedCancelSnooze(action.actionType)"
                                    ></v-btn>
                                </template>
                            </v-list-item>
                        </v-list>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>

        <v-row v-if="vessel && monitorId" class="mb-8">
            <v-col>
                <vessel-charts
                    :vessel-id="vesselId"
                    :account-id="accountId"
                    :monitor-id="monitorId"
                    :event-horizon="vessel.status?.dates?.eventHorizonDate"
                    :vessel-timezone-id="vesselTimezone"
                />
            </v-col>
        </v-row>

        <v-row v-if="vessel" class="mb-8">
            <v-col sm="12" md="6">
                <h3 class="mb-4">Chemicals</h3>
                <vessel-chemicals :account-id="accountId" :vessel-id="vesselId" :vessel-volume="vessel.volumeGallons" />
            </v-col>
        </v-row>

        <v-dialog v-model="showConfirmDeleteVesselModal" max-width="500px">
            <v-card>
                <v-card-title>Delete Vessel?</v-card-title>
                <v-card-text> This action is permanent and cannot be recovered. </v-card-text>
                <v-card-actions>
                    <v-btn color="grey-lighten-1" @click="showConfirmDeleteVesselModal = false">No, Cancel</v-btn>
                    <v-btn color="error" @click="deleteVessel">Yes, Delete Vessel</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <update-vessel-status-dialog
            v-if="vessel"
            :vessel-id="vessel.vesselId"
            v-model="showUpdateStatusDialog"
            @status-updated="statusUpdated"
        ></update-vessel-status-dialog>

        <add-water-report-modal :account-id="accountId" :vessel-id="vesselId" @water-report-added="waterReportAdded" v-model="showAddWaterReportModal" />

        <view-j-s-o-n-dialog v-model="showJsonDialog" :json="(vessel?.status as Record<string, unknown> | undefined)"></view-j-s-o-n-dialog>

        <v-snackbar v-model="showDeleteVesselSnackbar" multi-line :timeout="5000" color="red">
            <p>Oops! You must detach the monitor before you can delete this vessel.</p>
            <template v-slot:actions>
                <v-btn color="white" variant="text" @click="showDeleteVesselSnackbar = false">Close</v-btn>
            </template>
        </v-snackbar>

        <v-dialog v-model="showConfirmCancelSnoozeDialog" max-width="500px">
            <v-card>
                <v-card-title>Remove Snooze?</v-card-title>
                <v-card-text> This action is permanent and the user will be able to see this action after a refresh. </v-card-text>
                <v-card-actions>
                    <v-btn color="grey-lighten-1" @click="showConfirmCancelSnoozeDialog = false">No, Cancel</v-btn>
                    <v-btn color="error" @click="removeSnooze">Yes, Remove Snooze</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <action-details-modal v-model="showActionDetailsDialog" :account-id="accountId" :vessel-id="vesselId" :action-type="focusedActionType" />

        <edit-vessel-modal v-model="showEditVessel" @vessel-updated="vesselUpdated" />

        <v-dialog v-model="showChemCheckResultDialog">
            <v-card title="Results">
                <v-card-text>
                    <div v-if="chemEngineResult?.statusCode !== undefined">Status Code: {{ chemEngineResult?.statusCode }}</div>
                    <div v-if="chemEngineResult?.error !== undefined">Error: {{ chemEngineResult.error }}</div>

                    <div v-if="chemEngineResult?.payload !== undefined && chemEngineResult.payload !== null && chemEngineResult.payload !== 'null'">
                        <h4>Payload:</h4>
                        <pre>{{ chemEngineResult.payload }}</pre>
                    </div>

                    <div v-if="chemEngineResult?.logs !== undefined">
                        <h4>Logs:</h4>
                        <pre>{{ chemEngineResult.logs }}</pre>
                    </div>
                </v-card-text>
            </v-card>
        </v-dialog>

        <v-dialog v-model="showMigrateSubscriptionDialog">
            <v-card>
                <v-card-title>Migrate Subscription?</v-card-title>
                <v-card-text>This will migrate this vessel to an included subscription. Are you sure you want to do this?</v-card-text>
                <v-card-actions>
                    <v-btn color="grey-lighten-1" @click="showMigrateSubscriptionDialog = false">No, Cancel</v-btn>
                    <v-btn color="error" @click="migrateSubscription" :loading="migratingSubscription">Yes</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="showDeleteSubscriptionDialog">
            <v-card>
                <v-card-title>Delete Subscription?</v-card-title>
                <v-card-text>This will remove this subscription from stripe and our database. Are you sure you want to do this?</v-card-text>
                <v-card-actions>
                    <v-btn color="grey-lighten-1" @click="showDeleteSubscriptionDialog = false">No, Cancel</v-btn>
                    <v-btn color="error" @click="deleteSubscription" :loading="deletingSubscription">Yes</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="showSubscriptionResults">
            <v-card>
                <v-card-title>Results</v-card-title>
                <v-card-text>
                    <ul>
                        <li v-for="result of subscriptionResults" :key="result">{{ result }}</li>
                    </ul>
                </v-card-text>
                <v-card-actions>
                    <v-btn color="grey-lighten-1" @click="showSubscriptionResults = false">OK</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <vessel-testing-controls-modal :account-id="accountId" :vessel-id="vesselId" v-model="showVesselTestingControlsModal" @vessel-updated="vesselUpdated" />

        <v-dialog v-model="showActionStatusesModal">
            <v-card>
                <v-card-title>Action Statuses</v-card-title>
                <v-card-text>
                    <v-table>
                        <tbody>
                            <tr>
                                <th></th>
                                <th>Suppressed</th>
                                <th>Snoozed</th>
                                <td>Last Completed</td>
                            </tr>
                        </tbody>
                        <tbody>
                            <tr v-for="status of vessel?.actionStatuses" :key="status.actionType">
                                <th>
                                    <h4>{{ status.title }}</h4>
                                    <p style="font-weight: 300">{{ status.actionType }}</p>
                                </th>

                                <td>
                                    <v-chip v-if="status.suppressed" variant="flat" color="amber-darken-1" class="mr-2">Suppressed</v-chip>
                                    <time-ago v-if="status.suppressed" prepend="ends in" :date="status.suppressedUntilDate" :timezone-id="vesselTimezone" />
                                </td>

                                <td>
                                    <v-chip v-if="status.snoozed" variant="flat" color="amber-darken-1" class="mr-2">Snoozed</v-chip>
                                    <time-ago v-if="status.snoozed" prepend="ends in" :date="status.snoozedUntilDate" :timezone-id="vesselTimezone" />
                                    <v-btn
                                        v-if="status.snoozed"
                                        color="grey-lighten-1"
                                        icon="mdi-cancel"
                                        variant="text"
                                        class="cancelSnooze"
                                        @click="clickedCancelSnooze(status.actionType)"
                                    ></v-btn>
                                </td>

                                <td><time-ago :date="status.lastCompletedDate" :timezone-id="vesselTimezone" /></td>
                            </tr>
                        </tbody>
                    </v-table>
                </v-card-text>
                <v-card-actions>
                    <v-btn color="grey-lighten-1" @click="showActionStatusesModal = false">Ok</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <!-- <pre>{{ vessel }}</pre> -->
    </v-container>
</template>

<script setup lang="ts">
import {
    ActionType,
    type AccountVesselV1,
    type ActionDetailsV1,
    type PutRunVesselStatusUpdateV1,
    type SuppressedActionV1
} from '@general-galactic/crystal-api-client'
import { computed, ref, watch } from 'vue'
import VesselDisc from '@/components/VesselDisc.vue'
import { useBreadCrumbsStore } from '@/stores/breadCrumbs'
import { format } from 'date-fns'
import { useVesselStore } from '@/stores/vessel'
import VesselCharts from '../components/VesselCharts.vue'
import { useApi } from '@/api'
import UpdateVesselStatusDialog from '../components/debug/UpdateVesselStatusDialog.vue'
import { useGeneralStore } from '@/stores/general'
import VesselStatusReadings from '@/components/VesselStatusReadings.vue'
import { formatDateForVesselTimezone, handleApiError } from '@/lib/utils'
import { useRouter } from 'vue-router'
import AddWaterReportModal from '@/components/waterReports/AddWaterReportModal.vue'
import VesselChemicals from '@/components/chemicals/VesselChemicals.vue'
import { onMounted } from 'vue'
import ViewJSONDialog from '@/components/debug/ViewJSONDialog.vue'
import { useAccountStore } from '@/stores/account'
import EditVesselModal from '@/components/vessels/EditVesselModal.vue'
import SubscriptionCard from '@/components/vesselCards/SubscriptionCard.vue'
import AccountHealthCard from '@/components/vesselCards/AccountHealthCard.vue'
import AccountUsersCard from '@/components/vesselCards/AccountUsersCard.vue'
import MonitorCard from '@/components/vesselCards/MonitorCard.vue'
import LocationCard from '@/components/vesselCards/LocationCard.vue'
import VesselCard from '@/components/vesselCards/VesselCard.vue'
import VesselTestingControlsModal from '@/components/vessels/VesselTestingControlsModal.vue'
import TimeAgo from '@/components/TimeAgo.vue'
import ActionDetailsModal from '@/components/actions/ActionDetailsModal.vue'

const breadCrumbsStore = useBreadCrumbsStore()
const vesselStore = useVesselStore()
const accountStore = useAccountStore()
const router = useRouter()

const showActionDetailsDialog = ref(false)
const focusedActionType = ref<ActionType | undefined>()

const showUpdateStatusDialog = ref(false)
const showAddWaterReportModal = ref(false)
const showConfirmDeleteVesselModal = ref(false)
const showDeleteVesselSnackbar = ref(false)
const showJsonDialog = ref(false)
const showEditVessel = ref(false)
const showChemCheckResultDialog = ref(false)
const showVesselTestingControlsModal = ref(false)
const showActionStatusesModal = ref(false)

const showConfirmCancelSnoozeDialog = ref(false)
const actionTypeToCancelSnooze = ref<ActionType | undefined>()
const chemEngineResult = ref<PutRunVesselStatusUpdateV1 | undefined>()

const props = defineProps({
    accountId: {
        type: Number,
        required: true
    },
    vesselId: {
        type: Number,
        required: true
    }
})

const vessel = computed<AccountVesselV1 | undefined>(() => {
    return vesselStore.vessel
})

const vesselTimezone = computed(() => {
    return vessel.value?.location?.timeZone?.timeZoneId
})

const isAcclimating = computed(() => {
    return vessel.value?.states.acclimating === true
})

const showEngineeringOptions = computed(() => {
    return useGeneralStore().$state.showEngineeringOptions
})

const monitorId = computed(() => {
    if (vessel.value?.accountId !== props.accountId || vessel.value.vesselId !== props.vesselId) return undefined // timing issue, cached monitor is out of sync - lie for now
    return vessel.value?.monitorId
})

onMounted(() => {
    _loadInfo()
})

watch([() => props.accountId, () => props.vesselId], () => {
    _loadInfo()
})

function _loadInfo(forceLoad = false, forceGeoUpdate = false) {
    _updateBreadCrumb() //  show vessel # version
    getVesselDetails(forceLoad, forceGeoUpdate)
    accountStore.loadVesselAccountHealthInfo(props.accountId, forceLoad)
}

const getVesselDetails = async (forceLoad = false, forceGeoUpdate = false) => {
    await vesselStore.loadVessel(props.accountId, props.vesselId, forceLoad, forceGeoUpdate)
    _updateBreadCrumb() // update with vessel name
}

// Triggered by CTRL+D settings and deleting or expiring readings
const statusUpdated = async () => {
    _loadInfo(true)
}

const vesselUpdated = () => {
    getVesselDetails(true)
    showEditVessel.value = false
}

function _updateBreadCrumb() {
    breadCrumbsStore.$patch({
        items: _buildBreadCrumbItems()
    })
}

function _buildBreadCrumbItems() {
    return [
        {
            text: `Account #${props.accountId}`,
            to: {
                name: 'AccountDetails',
                params: {
                    accountId: props.accountId
                }
            }
        },
        {
            text: vessel.value?.name ?? `Vessel #${props.vesselId}`
        }
    ]
}

const navigateToGoogleMaps = () => {
    window.open(vessel.value?.map?.mapsUrl, '_googleMaps')
}

const actions = computed(() => {
    if (!vessel.value) return undefined
    return vessel.value.status?.actions
})

const suppressedActions = computed(() => {
    if (!vessel.value) return undefined
    return vessel.value.status?.suppressedActions
})

const suppressedActionSubTitle = (suppressedAction: SuppressedActionV1) => {
    const items: string[] = [] //suppressedAction.reason
    // if (suppressedAction.suppressedUntilDate) items.push(`for the next ${formatDistanceToNowStrict(suppressedAction.suppressedUntilDate)}`)
    items.push(suppressedAction.reasonDetails)
    return items.join(', ')
}

const lastCompletedDateForActionType = (actionType: ActionType) => {
    const matching = vessel.value?.actionStatuses.find((as) => as.actionType === actionType)
    return matching?.lastCompletedDate
}

const suppressedActionEndDate = (suppressedAction: SuppressedActionV1) => {
    if (suppressedAction.suppressedUntilDate === undefined) return 'Suppression has no end date'
    const vesselTimezoneId = vessel.value?.location?.timeZone?.timeZoneId
    return `Suppression ends at ${formatDateForVesselTimezone({ date: suppressedAction.suppressedUntilDate, vesselTimezoneId })}`
}

const showAction = async (action: ActionDetailsV1) => {
    focusedActionType.value = action.type
    showActionDetailsDialog.value = true
}

const updatedReading = () => {
    statusUpdated()
}

const showStatusJSON = () => {
    showJsonDialog.value = true
}

const navigateToMonitorDetails = () => {
    router.push({ name: 'MonitorDetails', params: { monitorId: vessel.value?.monitorId } })
}

const startAcclimating = async () => {
    await handleApiError(async () => {
        const result = await useApi().putVesselStartAcclimatingV1({
            accountId: props.accountId,
            vesselId: props.vesselId,
            putVesselStartAcclimatingV1: {
                acclimatingMinutes: 30
            }
        })
        console.log('Started Acclimating', result)

        await _loadInfo(true)
    })
}

const stopAcclimating = async () => {
    await handleApiError(async () => {
        const result = await useApi().delVesselStopAcclimatingV1({
            accountId: props.accountId,
            vesselId: props.vesselId
        })
        console.log('Stopped Acclimating', result)

        await _loadInfo(true)
    })
}

const waterReportAdded = async () => {
    await _loadInfo(true)
}

const showConfirmDeleteVessel = () => {
    if (monitorId.value !== undefined) {
        showDeleteVesselSnackbar.value = true
        return
    }

    showConfirmDeleteVesselModal.value = true
}

const viewProtocolInfo = () => {
    router.push({ name: 'ProtocolInfo', params: { accountId: props.accountId, vesselId: props.vesselId } })
}

const deleteVessel = async () => {
    await handleApiError(async () => {
        const result = await useApi().removeAccountVesselV1({
            accountId: props.accountId,
            vesselId: props.vesselId
        })

        if (result.deleted) {
            console.log('Deleted account vessel', result.deleted)
            router.replace({ name: 'AccountDetails', params: { accountId: props.accountId } })
        } else {
            throw new Error('Failed to delete vessel')
        }
    })
}

const removeSnooze = async () => {
    const actionType = actionTypeToCancelSnooze.value
    if (actionType === undefined) return

    await handleApiError(
        async () => {
            const result = await useApi().delVesselActionSnoozeV1({
                accountId: props.accountId,
                vesselId: props.vesselId,
                actionType
            })

            if (result.cancelled) {
                console.log('Cancelled snooze: ', actionType)
            }

            _loadInfo(true)
        },
        async () => {
            showConfirmCancelSnoozeDialog.value = false
        }
    )
}

const clickedCancelSnooze = (actionType: ActionType) => {
    actionTypeToCancelSnooze.value = actionType
    showConfirmCancelSnoozeDialog.value = true
}

const runTimedChemCheck = () => {
    if (vessel.value === undefined) return

    const { accountId, vesselId } = vessel.value
    handleApiError(async () => {
        chemEngineResult.value = await useApi().putRunVesselStatusUpdateV1({
            accountId,
            vesselId
        })
        showChemCheckResultDialog.value = true
    })
}

const showMigrateSubscriptionDialog = ref(false)
const showDeleteSubscriptionDialog = ref(false)

const migratingSubscription = ref(false)
const deletingSubscription = ref(false)

const showSubscriptionResults = ref(false)
const subscriptionResults = ref<string[] | undefined>()

const showConfirmMigrateSubscription = () => {
    showMigrateSubscriptionDialog.value = true
}

const showConfirmDeleteSubscription = () => {
    showDeleteSubscriptionDialog.value = true
}

const migrateSubscription = () => {
    handleApiError(
        async () => {
            migratingSubscription.value = true
            const result = await useApi().putMigrateIncludedSubscriptionV1({
                accountId: props.accountId,
                vesselId: props.vesselId
            })
            await getVesselDetails(true)
            subscriptionResults.value = result.actions
            showMigrateSubscriptionDialog.value = false
            showSubscriptionResults.value = true
        },
        async () => {
            migratingSubscription.value = false
        }
    )
}

const deleteSubscription = () => {
    handleApiError(
        async () => {
            deletingSubscription.value = true
            const result = await useApi().delMigratedIncludedSubscriptionV1({
                accountId: props.accountId,
                vesselId: props.vesselId
            })
            await getVesselDetails(true)
            subscriptionResults.value = result.actions
            showDeleteSubscriptionDialog.value = false
            showSubscriptionResults.value = true
        },
        async () => {
            deletingSubscription.value = false
        }
    )
}
</script>

<style lang="scss" scoped>
.needsAttention {
    h1 {
        color: #f67d00;
    }

    .unit {
        color: rgba(246, 125, 0, 0.7);
    }
}

.danger {
    h1 {
        color: #da1f1f;
    }

    .unit {
        color: rgba(218, 31, 31, 0.7);
    }
}

.actionButton {
    margin-bottom: 20px;
    padding: 0 0 0 20px;
    border: 0.5px solid #ddd;
    height: 63px;
    min-width: 300px;
    border-radius: 6px;
    margin-bottom: 10px;
    overflow: hidden;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
}

.cancelSnooze {
    &:hover {
        color: red !important;
    }
}
.actionDetails {
    H1 {
        margin-bottom: 20px;
    }

    .chemicalImage {
        margin-bottom: 30px;
    }

    UL {
        margin-top: 30px;
        list-style: none;
    }
}

.vesselDisc {
    position: relative;
    text-align: center;

    .editStatusButton {
        position: absolute;
        top: 40px;
        right: 40px;
    }
}

.suppressedAction {
    background-color: #eee;
    margin-bottom: 20px;
    padding: 0 0 0 20px;
    border: 0.5px solid #ddd;
    height: 63px;
    min-width: 300px;
    border-radius: 6px;
    margin-bottom: 10px;
    overflow: hidden;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
}

.chemEngineInfo {
    TD {
        position: relative;
    }

    small {
        color: #666;
        position: relative;
        margin-top: -4px;
    }

    border-radius: 6px;
}

.contentCard {
    margin: 20px;
}

.recommendation {
    border-color: silver;
}

.actionIcon {
    width: 40px;
    height: 40px;
}
</style>
