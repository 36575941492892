<template>
    <span>
        <v-card color="#952175" theme="dark" v-if="vessel?.monitorId" class="vesselCard clickable" height="200px">
            <v-card-title>{{ vessel.monitorId }}</v-card-title>

            <v-card-subtitle v-if="vessel.status?.dates?.lastMonitorCheckinDate">
                checked in {{ formatDistanceToNowStrict(vessel.status?.dates?.lastMonitorCheckinDate, { addSuffix: true }) }}
            </v-card-subtitle>
            <v-card-subtitle v-else> hasn't checked in </v-card-subtitle>

            <v-card-subtitle v-if="vessel.monitorAttachedAt">
                attached {{ formatDistanceToNowStrict(vessel.monitorAttachedAt, { addSuffix: true }) }}
            </v-card-subtitle>

            <v-card-subtitle v-if="vessel.monitor?.sensor">
                Sensor #{{ vessel.monitor.sensor.serialNumber }} &#8226; {{ vessel.monitor.sensor.type }}
            </v-card-subtitle>

            <v-card-subtitle v-if="vessel.states.promptForSensorScan">NEEDS SENSOR SCAN</v-card-subtitle>

            <v-btn
                icon="mdi-close-circle"
                @click.stop="clickedDetachMonitor"
                title="Detach Monitor"
                variant="text"
                density="compact"
                size="x-large"
                class="vesselCardAction"
            ></v-btn>
        </v-card>

        <v-card v-else color="white" theme="dark" class="vesselCard noMonitorCard" height="200px">
            <v-card-title>NO MONITOR</v-card-title>
            <v-card-text>
                <v-btn icon size="small" color="#952175" @click="showAttachMonitorDialog = true">
                    <v-icon>mdi-plus</v-icon>
                </v-btn>
            </v-card-text>
        </v-card>

        <detach-monitor-modal
            v-if="vessel?.monitorId"
            :account-id="accountId"
            :vessel-id="vesselId"
            :monitor-id="vessel.monitorId"
            v-model="showDetachMonitor"
            @monitor-detached="monitorDetached"
        />

        <v-dialog v-model="showAttachMonitorDialog">
            <v-card :min-width="400">
                <v-card-title>Choose a Monitor</v-card-title>
                <v-card-text>
                    <choose-monitor @picked:monitor="pickedMonitorToAttach" />
                </v-card-text>
            </v-card>
        </v-dialog>
    </span>
</template>

<script lang="ts" setup>
import { useVesselStore } from '@/stores/vessel'
import { computed, ref } from 'vue'
import { formatDistanceToNowStrict } from 'date-fns'
import type { MonitorsSearchResultV1 } from '@general-galactic/crystal-api-client'
import { handleApiError } from '@/lib/utils'
import { useApi } from '@/api'
import DetachMonitorModal from '@/components/vessels/DetachMonitorModal.vue'
import ChooseMonitor from '@/components/monitors/ChooseMonitor.vue'

const props = defineProps({
    accountId: {
        type: Number,
        required: true
    },
    vesselId: {
        type: Number,
        required: true
    }
})
const emits = defineEmits(['attachedMonitor', 'detachedMonitor'])

const vesselStore = useVesselStore()
const showAttachMonitorDialog = ref(false)
const showDetachMonitor = ref(false)

const vessel = computed(() => {
    return vesselStore.vessel
})

const clickedDetachMonitor = (e: Event) => {
    e.preventDefault()
    showDetachMonitor.value = true
    return false
}

const pickedMonitorToAttach = async (monitor: MonitorsSearchResultV1) => {
    await handleApiError(
        async () => {
            if (monitor.vesselId && monitor.vesselId !== props.vesselId) throw new Error(`This monitor is already attached to a vessel.`)

            const result = await useApi().setVesselMonitorV2({
                accountId: props.accountId,
                vesselId: props.vesselId,
                setVesselMonitorV1: {
                    monitorId: monitor.monitorId,
                    ownByAccountIfUnowned: true
                }
            })
            console.log('Attached monitor', result)
            emits('attachedMonitor')
        },
        async () => {
            showAttachMonitorDialog.value = false
        }
    )
}

const monitorDetached = () => {
    emits('detachedMonitor')
}
</script>

<style lang="scss" scoped>
.noMonitorCard {
    text-align: center;
    border: 3px dashed #952175;
    color: black;

    .v-btn {
        color: white;
    }
}
</style>
