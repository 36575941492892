<template>
    <v-row>
        <v-col>
            <h4 class="mb-3">Monitor</h4>
            <div v-if="monitorReadings.length">
                <reading-cell
                    v-for="monitorReading of monitorReadings"
                    :key="monitorReading.readingType"
                    :reading-type="monitorReading.readingType"
                    :accountId="accountId"
                    :vesselId="vesselId"
                    :title="monitorReading.title"
                    :reading="monitorReading"
                    :show-raw="true"
                    :vessel-timezone-id="vesselTimezoneId"
                    @updated-reading="updatedReading"
                ></reading-cell>
            </div>
            <div v-else>No Current Readings</div>
        </v-col>
        <v-col>
            <h4 class="mb-3">
                Water Report <v-btn @click="showWaterReportHistory" size="x-small" color="blue-darken-2" class="float-right">view history</v-btn>
            </h4>
            <div v-if="reportReadings.length">
                <reading-cell
                    v-for="reportReading of reportReadings"
                    :key="reportReading.readingType"
                    :reading-type="reportReading.readingType"
                    :accountId="accountId"
                    :vesselId="vesselId"
                    :title="reportReading.title"
                    :reading="reportReading"
                    :show-raw="false"
                    :vessel-timezone-id="vesselTimezoneId"
                    @updated-reading="updatedReading"
                ></reading-cell>
            </div>
            <div v-else>No Current Readings</div>

            <div v-if="computedReadings.length">
                <h4 class="mt-5 mb-3">Computed Readings</h4>
                <reading-cell
                    v-for="reading of computedReadings"
                    :key="reading.readingType"
                    :reading-type="reading.readingType"
                    :accountId="accountId"
                    :vesselId="vesselId"
                    :title="reading.title"
                    :reading="reading"
                    :show-raw="false"
                    :vessel-timezone-id="vesselTimezoneId"
                    @updated-reading="updatedReading"
                ></reading-cell>
            </div>
        </v-col>
    </v-row>

    <v-row v-if="lowTempMode !== undefined">
        <v-col>
            <low-temp-mode :low-temp-mode="lowTempMode" :vessel-timezone-id="vesselTimezoneId" />
        </v-col>
        <v-col></v-col>
    </v-row>
</template>

<script lang="ts" setup>
import { computed, type PropType } from 'vue'
import ReadingCell from '@/components/ReadingCell.vue'
import type { ReadingsV1, ReadingTypesV1, VesselStatusV1LowTempMode } from '@general-galactic/crystal-api-client'
import router from '@/router'
import { readingConfigsByKey, type ReadingWithTitle } from '@/lib/readings'
import LowTempMode from '@/components/vessels/LowTempMode.vue'

const props = defineProps({
    accountId: {
        type: Number
    },
    vesselId: {
        type: Number
    },
    monitorReadings: {
        type: Object as PropType<ReadingsV1>
    },
    reportReadings: {
        type: Object as PropType<ReadingsV1>
    },
    computedReadings: {
        type: Object as PropType<ReadingsV1>
    },
    lowTempMode: {
        type: Object as PropType<VesselStatusV1LowTempMode>
    },
    vesselTimezoneId: {
        type: String
    }
})

function _readingsForSource(source: ReadingsV1 | undefined): ReadingWithTitle[] {
    if (!source) return []

    const output: ReadingWithTitle[] = []
    for (const readingType of Object.keys(source) as ReadingTypesV1[]) {
        const reading = source[readingType as keyof ReadingsV1]
        if (reading) {
            output.push({
                ...reading,
                readingType,
                title: readingConfigsByKey[readingType].title
            })
        }
    }
    return output
}

const monitorReadings = computed(() => {
    return _readingsForSource(props.monitorReadings)
})

const reportReadings = computed(() => {
    return _readingsForSource(props.reportReadings)
})

const computedReadings = computed(() => {
    return _readingsForSource(props.computedReadings)
})

const emit = defineEmits(['updatedReading'])

const updatedReading = () => {
    emit('updatedReading')
}

const showWaterReportHistory = () => {
    router.push({ name: 'WaterReportHistory', params: { accountId: props.accountId, vesselId: props.vesselId } })
}
</script>

<style lang="scss" scoped>
.readings {
    max-width: 300px;
}

.invalidReadingsMessage {
    color: red;
}

h4 {
    font-weight: 300;
}

.date {
    color: #666;
    font-weight: 300;
    font-size: 14px;
    text-align: center;
}
</style>
